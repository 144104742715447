import React from 'react';
import './style.css';

function Footer() {
    return (
        <footer>
            <h4>Made with <span role="img" aria-label="heart">❤️</span> and REACT</h4>
        </footer>
    );
}

export default Footer;