import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Resume() {
    return (
        <div>
            <Header />
            <section>
                <div>
                    <h2>Coming soon!</h2>
                 </div>
            </section>
            <Footer />
        </div>
    )
}